.centered {
    display: flex;
    align-items: center; 
    justify-content: center;
}

.slide-container {
    margin-top: 30px;
    height: 600px;
}

.empty-confirmed-preview-container {
    height: 595px;
    width: 160px;
    border: 2px solid;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #1890ff1a;
    padding: 5px;
    box-shadow: 0px 4px 8px 2px green;
    transition-duration: 200ms;
    transition-timing-function: ease-in;
}

.empty-unconfirmed-preview-container {
    height: 595px;
    width: 160px;
    border: 2px solid;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #1890ff1a;
    padding: 5px;
    transition-duration: 200ms;
    transition-timing-function: ease-in;
}

.filled-preview-container {
    height: 595px;
    width: 166px;
    border: 2px solid;
    background-size: 100%;
    background-position-y: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
}

.confirmed-preview-slide {
    height: 595px;
    width: 166px;
    border: 2px solid;
    background-size: 100%;
    background-position-y: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    box-shadow: 5px 10px green;
}

.empty-slide {
    height: 350px;
}

.filled-slide {
    height: 350px;
    cursor: pointer;
}

.filled-slide > p {
    margin-bottom: 0px;
}

.filled-action-ongoing-slide {
    height: 350px;
}

.filled-action-ongoing-slide > p {
    margin-bottom: 0px;
}

.slot-image-blood {
    height: 319px;
    width: 130px;
    /* transform: rotate(90deg); */
    margin-left: 25px;
    margin-top: 25px;
    padding: 0px 2px 0px 2px;
}

.slot-image {
    height: 146px;
    width: 319px;
    transform: rotate(90deg);
    margin-left: -83px;
    margin-top: 95px;
    padding: 0px 2px 0px 2px;
}

.barcode-filled {
    border-top: 1px solid grey;
    height: 150px;
    width: 146px;
    margin-bottom: 0px;
    position: relative;
    cursor: pointer;
}

.barcode-empty {
    border-top: 1px solid grey;
    height: 150px;
    width: 146px;
    background-color: #6495ed36;
}

.barcode-image {
    padding: 2px 0px 2px 0px;
    height: inherit;
    width: inherit;
}

.quick-settings-button-group {
    width: 100%;
    background-color: white;
}

.quick-settings-button {
    height: 20px; 
    width: 100%;
}

/* .checkbox {
    margin: -20px;
    padding: 0px !important;
} */

.checkbox >  span {
    padding: 0px !important;
}

.barcode-filled:hover .rotate {
	display: block;
}

.rotate {
    position: absolute;
    left: 33%;
    top: 30%;
    display: none;
    font-size: 50px;
}

.ant-input-number-input {
    height: 22px;
}