.custom-hover-table .ant-table .ant-table-tbody > tr:hover > td {
    background: unset;
}

.normal-cursor {
  cursor: default;
}

.custom-hover-table-box:hover {
  /* box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 10px 10px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  -moz-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 10px 10px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  -webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 10px 10px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  z-index: 1; */
  background: rgba(198, 223, 240, 0.902);
}

.custom-hover-table-case .ant-table .ant-table-tbody > tr:hover > td {
  background: unset;
}

.custom-hover-table-case .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.custom-hover-table-case .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.custom-hover-table-case .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.custom-hover-table-case .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: unset;
}

.unread {
  background: rgba(255,255,255,0.902);
}

.read {
  background: rgba(242,245,245,0.8);
}

.custom-table-selection .ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #c2dbff;
}

.custom-table-selection .ant-table-tbody > tr:hover.ant-table-row-selected td {
  background: #c2dbff;
}

.custom-table-header .ant-table-thead > tr > th .ant-table-header-column {
  display: block;
  max-width: 100%;
}

/* .custom-table-header .ant-table-thead > tr {
  background-color: #3b7199;
}

.custom-table-header .ant-table-thead > tr:hover {
  background-color: #3b7199;
} */

.custom-table-height .ant-checkbox {
  padding: 5px !important;
}

.custom-table-header .ant-table-thead > tr > th .ant-table-header-column {
  display: block;
  max-width: 100%;
/*  background-color: #03112c;*/
}

.custom-table-header-blood {
  display:block;
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.custom-table-header-blood .ant-table-thead {
  width: 100%;
}

.custom-table-header-blood .ant-table-tbody {
  width: 100%;
}

.custom-table-header-blood .ant-table-thead > tr > th {
  background-color: #03112c;
  padding:9px 8px;
  white-space:pre-wrap;
  text-overflow: ellipsis;
  font-size: 0.9vw;
}

.custom-table-header-blood .ant-table-tbody > tr > td {
  padding:9px 8px;
  color: white;
  white-space:pre-wrap;
  text-overflow: ellipsis;
  font-size: 0.8vw;
}

.custom .ant-checkbox {
  padding: 1px !important;
  height: 10px !important;
  width: 10px !important;
}

@media only screen and (max-width: 1800px) and (min-width: 1601px) {
  .custom-table-header-blood .ant-table-tbody > tr > td {
    padding:9px 8px;
  }
  .custom-table-header-blood .ant-table-thead > tr > th {
    padding:9px 8px;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1401px) {
  .custom-table-header-blood .ant-table-tbody > tr > td {
    padding:8px 6px;
    font-size: 1vw;
  }
  .custom-table-header-blood .ant-table-thead > tr > th {
    padding:8px 6px;
    font-size: 1vw;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .custom-table-header-blood .ant-table-tbody > tr > td {
    padding:8px 4px;
    font-size: 1.1vw;
  }
  .custom-table-header-blood .ant-table-thead > tr > th {
    padding:8px 4px;
    font-size: 1.1vw;
  }
}

@media only screen and (max-width: 1199px) {
  .custom-table-header-blood .ant-table-tbody > tr > td {
    padding:8px 1px;
    font-size: 1.2vw;
  }
  .custom-table-header-blood .ant-table-thead > tr > th {
    padding:8px 1px;
    font-size: 1.2vw;
  }
}
