/* .App {
  font-family: sans-serif;
  background: #1c1d1f;
  color: white;
  height: 100vh;
} */

.react-tabs {
  display: flex;
  color: cyan;
  background: #03112c;
}

img.imageDim {
  filter: brightness(25%) !important;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 3vw;
  margin: 0;
  padding: 0;
  color: cyan;
  background: #0b1c3b;
  height: 95.5vh;
}

.react-tabs__tab {
  height: 4.5vh;
  list-style: none;
  cursor: pointer;
  color: #bbb;
  text-align: center;
  font-weight: bold;
  padding-top: 0.75vh;
  width: 3vw;
  font-size: 1vw;
}

.react-tabs__tab--selected {
  height: 4.5vh;
  width:3vw;
  background: #03112c;
  border-color: #03112c;
  list-style: none;
  border-left: 4px solid cyan;
  color: cyan;
  font-weight: bold;
  text-align: center;
  padding-top: 0.75vh;
  font-size: 1vw;
}

#unconfirmed, #ordered {
  height:4vh;
  display:block;
  /* margin-top: -5vh; */
}

#unconfirmed > .react-tabs__tab-list, #ordered > .react-tabs__tab-list {
  flex-direction: row;
  width: inherit;
  margin: 0;
  padding: 0;
  height: 4vh;
}

#unconfirmed > .react-tabs__tab-list > .react-tabs__tab, #ordered > .react-tabs__tab-list > .react-tabs__tab {
  display:inline-block;
  height: 4vh;
  width: 50%;
  font-size: 0.8vw;
}

#unconfirmed > .react-tabs__tab-list > .react-tabs__tab--selected, #ordered > .react-tabs__tab-list > .react-tabs__tab--selected {
  display:inline-block;
  height: 4vh;
  width: 50%;
  border-left: 0px;
  border-bottom: 4px solid cyan;
}

.react-tabs__tab-panel {
  display: block;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.panel-content {
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .react-tabs__tab {
    font-size: 20px;
    height: 7vh;
    width: 5vw;
  }

  .react-tabs__tab > p {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .react-tabs__tab--selected {
    height: 7vh;
    width: 5vw;
  }

  .react-tabs__tab-list {
    width: 5vw;
  }

  #unconfirmed > .react-tabs__tab-list > .react-tabs__tab, #ordered > .react-tabs__tab-list > .react-tabs__tab {
    font-size: 1vw;
  }

  #unconfirmed > .react-tabs__tab-list > .react-tabs__tab > p, #ordered > .react-tabs__tab-list > .react-tabs__tab > p {
    margin-top: 0.5vh;
    margin-bottom: 0vh;
  }

}

@media only screen and (max-width: 1000px) {
  .react-tabs__tab {
    font-size: 12px;
    height: 9vh;
    width: 5vw;
  }

  .react-tabs__tab > p {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .react-tabs__tab--selected {
    height: 9vh;
    width: 5vw;
  }

  .react-tabs__tab-list {
    width: 5vw;
  }

  #unconfirmed > .react-tabs__tab-list > .react-tabs__tab, #ordered > .react-tabs__tab-list > .react-tabs__tab {
    font-size: 1vw;
  }

  #unconfirmed > .react-tabs__tab-list > .react-tabs__tab > p, #ordered > .react-tabs__tab-list > .react-tabs__tab > p {
    margin-top: 0.5vh;
    margin-bottom: 0vh;
  }
}
